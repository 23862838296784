export default {
    serverProtocol: 'https',
    serverIp: 'back.hwangchilcoin.com',
  
    // serverProtocol: 'http',
    // serverIp: "localhost",  

    serverPort: '18001',
    serverContext: '',
      
    enableForeignExchangeRate: false,
  
    appCode: 'HwangChil'
  };
  