import { Input, Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate  } from "react-router-dom";
import { useLocation  } from "react-router-dom";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import Header from '../Header';
import Top from '../../pages/Top';
import { ArrowLeftOutlined, } from '@ant-design/icons';

const WalletReceive = () =>  {
    const location = useLocation();
    const navigate = useNavigate();
    const string = useSelector(state => state.stringReducer.string);    
    const [priceInputShow, setPriceInputShow] = useState(false);
    const [priceOk, setPriceOk] = useState(false);
    const [price, setPrice] = useState('');

    const copy = () => {
        const text = document.createElement("textarea")
        document.body.appendChild(text)
        text.value = location.state.data.address
        text.select();
        document.execCommand('copy');
        document.body.removeChild(text);
        Modal.info({
            content: string.copyComplete
        })
    }

    return (
        <div id="receive">
            <Top />
            <Header />
            <div className='backIcon' onClick={()=>navigate(-1)}>
                <ArrowLeftOutlined /> Back
            </div>
            {priceInputShow && !priceOk ?
              <div className='walletBtn'>                    
                <Button className="moreBtn" onClick={() => setPriceOk(true)} >
                    {string.ok}
                </Button>            
                <Button className="moreBtn2" onClick={() => setPriceInputShow(false)} >
                    {string.cancel}
                </Button>                    
                </div>
                :
                <div className='walletBtn'>   
                    <Button
                        className="moreBtn"
                        onClick={copy}
                    >{string.copyAddress}
                    </Button>
                </div>
            }    
            <div className='qrBox'>        
                <QRCode    
                    size={200}
                    color='#000'
                    value={location.state.data.address}
                />
                <div className="address">{location.state.data.address}</div>
            </div>
            {priceInputShow &&
                <div className='priceBox'>
                    <div>{string.qrContainPrice}: </div>
                    {priceOk ?
                        <div style={{ display: "inline-block", marginLeft: 20 }}>{price}</div>
                        :
                        <div className='inputbox'>
                        <Input style={{ width: 160 }} value={price}
                            onChange={(e) => setPrice(e.target.value) } />
                        </div>
                    }
                    <span>{location.state.data.symbol}</span>
                </div>
            }

        </div>
    )
}

export default WalletReceive;