import React, { useState } from 'react';
import { Popover } from 'antd';
import { stringReducer } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../img/logo.png";
import global from "../img/common/global.png";
import { httpUrl, httpPost, httpGet } from "../api/httpClient";

const SignUpOk = () => {
    const string = useSelector(state => state.stringReducer.string);
    const language = useSelector(state => state.stringReducer.language);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [globalOpen, setGlobalOpen] = useState(false);

    const changeLan = (lan) =>{ dispatch(stringReducer(lan)) };
    
    const resendEmail = () => {       
        if (location.state == null || location.state.email == null || location.state.email.length < 4) {
            alert('이메일 정보가 없습니다. 로그인창에서 로그인 시도 후 재전송해주세요.');
            return;
        }
        const email = location.state.email;
        httpGet(httpUrl.userResendEmailSignup, [email]).then((result)=>{
            result = result.data;
            if (result == "SUCCESS"){
                alert(string.emailResent)
            } else {
                let message = string.emailResendFailed;
                if (result == "INVALID_EMAIL") message = string.invalidEmail;
                if (result == "ALREADY_VERIFIED") message = string.emailAlreadyVerified;
                if (result == "RECENTLY_SENT") message = string.emailRecentlySent;
                alert(message)   
            }
      
        }).catch(e=>{
            console.log ("## resendEmail error: " + e);
        });
    }
    return (
        <div id="signupok">
             <div className='header'>
                <Popover                
                    content={
                        <div className='pophover'>
                        <div onClick={()=>changeLan('ko')} className={language == 'ko' ? "lan active": "lan"}>한국어</div>
                        <div onClick={()=>changeLan('en')} className={language == 'en' ? "lan active": "lan"}>English</div>
                        <div onClick={()=>changeLan('ja')} className={language == 'ja' ? "lan active": "lan"}>日本語</div>
                        </div>                    
                    }
                    trigger={"click"}
                    open={globalOpen}
                    onOpenChange={()=>setGlobalOpen(!globalOpen)}>
                    <img src={global} className='globalImg'/>
                </Popover>
            </div>
            <div className='content'>
                <img src={logo} className='logoImg'/>
                <p className='welcome'>{string.welcome}</p>
                <p className='content'>
                    {string.signUpMsg01}<br/>
                    {string.signUpMsg02}</p>
            </div>
            <div className='btnbox'>
                <button className='loginBtn' onClick={()=> navigate('/')} >{string.login}</button>
            </div>
               
            <div style={{marginTop: 16, textAlign:'center'}}>
                {string.emailResendGuide1}<br/>
                {string.emailResendGuide2}
            </div>
            <div className='btnbox'>              
                <button className="resend-btn-ok" onClick={resendEmail}>{string.emailResend}</button>                 
            </div>
        </div>
    )
}

export default SignUpOk;